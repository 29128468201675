.rounded-circle {
    margin-left: 10px;
}

.recipes-hidden {
    display: none;
}

.recipes-visible {
    display: relative;
}


.dropdown {
    width: 100%;
    border-radius: 10px;
    box-shadow: 0 10px 25px rgba(0, 0, 0, .1);
    background-color: white;
}

thead {
    background: #f8f9fa !important;
    /* background: rgb(85, 138, 185) !important; */

}

.checkcard {
    background-color: aliceblue;
}

.fullwidth {
    width: 100%;
}

.css-1qsnayc-MuiTableContainer-root {
    border-radius: 0.5rem !important;
}

th>* {
    color: black !important;
    /*  font-weight: bold !important; */
}

.dropdown-header {
    height: 63px;
    padding: 15px;
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    align-items: center;

}

.uvagriot-table {
    border-radius: 0.5rem;
    background: linear-gradient(223deg, #49a3f161, #1a73e8a3) !important;
}

.uvagriot-table>thead {
    background: rgb(73, 43, 115, 0.70);
    color: ghostwhite;
}

.dropdown-header>div {
    margin-top: 5px;
    background-color: rgb(73, 43, 115);
    color: white;
    border-radius: 10px;
    outline-offset: 4px;
}

.front {
    display: block;
    font-size: 1rem;
    padding: 5px 5px;
    border-radius: 10px;
    background: rgb(146, 71, 252);
    color: white;
    transform: translateY(-4px);
}



.dropdown-body {
    padding: 5px;
    border-top: 1px solid #E5E8EC;
    display: none;
    width: 100%;
}

.divider-right {
    border: solid rgb(230, 230, 230);
    border-width: 0 2px 0 0;
    padding: 0 20px 0 0;
}

.btn-purple {
    background-color: rgb(146 71 252);
}

.dropdown-body.open {
    display: block;
    position: absolute;
    background-color: white;
    border-radius: 0 0 10px 10px;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 25px;
    z-index: 10;

}

.dropdown:has(.open) {
    border-radius: 10px 10px 0px 0px;

}


.dropdown-item {
    padding: 10px;
    margin: 0 5px 5px 0;
}

.dropdown-item:last-child {
    padding: 10px;
    margin: 0 5px 0px 0;
}

.dropdown-item:hover {
    cursor: pointer;
    border-radius: 10px;
}

.dropdown-item-dot {
    opacity: 0;
    color: #91A5BE;
    transition: all .2s ease-in-out;
}

.dropdown-item.selected>.dropdown-item-dot {
    opacity: 1;
}

.dropdown-item.selected {
    background-color: rgb(146 71 252 / 23%);
    border-radius: 10px
}


.icon {
    font-size: 13px;
    color: #91A5BE;
    transform: rotate(0deg);
    transition: all .2s ease-in-out;
}

.icon.open {
    transform: rotate(90deg);
}

.scrollable {
    border-radius: 0px;
    max-height: 375px;
}

.scrollbar-track, .scrollbar-track-y {
    padding: 0 !important;
    z-index: 0 !important;
}

.scroll-content {
    padding: 10px 20px 10px 0;
}

.modal {
    --bs-modal-zindex: 1200 !important;
    background-color: rgba(0, 0, 0, 0.212);
}


.Mui-active, .MuiSlider-thumb {
    width: 20px !important;
    height: 20px !important;
}

.borderless-card {
    border: 0 !important;
}