input[type="checkbox"]:disabled {
    background: gray;

}

.MuiDataGrid-booleanCell[data-value="false"] {
    color: rgb(255 0 0) !important;
}

.MuiDataGrid-booleanCell[data-value="true"] {
    color: rgb(47, 184, 43) !important;
}


.css-a8rluf {
    padding: 12px !important;
}

/* .css-a8rluf:first-child {
    padding: 0 5px 0 10px !important;
} */

.scroll-content {
    padding: 0px !important
}

.p-confirm-popup {
    box-shadow: 0 1px 3px rgb(0 0 0 / 8%) !important;
}

.MuiPopover-paper {
    background-color: white !important;
}

.MuiTablePagination-root p {
    margin-bottom: 0 !important;
}